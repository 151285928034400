import buttonstyles from "./Button.module.css";
import styles from "./Career.module.css";
import Anchorlink from "react-anchor-link-smooth-scroll";

const Career = () => {
  return (
    <section id="careers" className={styles.vacancy}>
      <div className={styles.vacancyContent}>
        <div className={styles.vacancyContent1}>
          <div className={styles.vacancyTitle}>Careers</div>
          <h1 className={styles.vacancyDescription}>Join our team</h1>
          <div className={styles.vacancyDescription1}>
            Join our team and be part of the journey where passion meets
            purpose, crafting the next generation of innovative products
            inspired by our team’s relentless pursuit of building software
            products.
          </div>
          <Anchorlink href="#contact-us" className={styles.text}>
          <div className={buttonstyles.button}>
           <span className={styles.text}>See Open Position</span> 
          </div>
          </Anchorlink>
        </div>
        <img className={styles.imageIcon} alt="" src="/career-image.png" />
        {/* <img className={styles.imageIcon1} alt="" src="/image-6@2x.png" /> */}
      </div>
    </section>
  );
};

export default Career;
