import FeatureList from "./FeatureList";
import styles from "./Features.module.css";

const List= [
  {
    "image": "featured-icon.svg", 
    "title": "Innovative Product Development",
    "description":" We are a product-based company dedicated to developing cutting-edge solutions that address real-world challenges."
  },
  {
    "image": "featured-icon.svg", 
    "title": "Customer-Centric Approach",
    "description":" We strive to deliver products that exceed expectations and drive value for our clients."
  },
  {
    "image": "featured-icon.svg", 
    "title": "Commitment to Quality",
    "description":" From product design to development and support, we maintain the highest standards to ensure that our solutions are reliable, robust, and effective."
  },
  {
    "image": "featured-icon.svg", 
    "title": "Impact-Driven",
    "description":"Our  goal is to make a positive impact on the world through our products. We aim to create solutions that make a difference."
  },
]
const Features = () => {
  return (
    <section id="who-we-are" className={styles.features}>
      <h1 className={styles.featuresHeader}>Who we are</h1>
      <div className={styles.featuresBody}>
        {
          List.map((item,index) => (
            <FeatureList content={item} />
          ))
        }
        
      </div>
    </section>
  );
};

export default Features;
