import styles from "./Header.module.css";
import Anchorlink from "react-anchor-link-smooth-scroll";
import buttonstyles from "./Button.module.css";
import Hamburger from "./Hamburger";

const Header = () => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.navigation}>
          <a href="/" >
          <img
            className={styles.logoIcon}
            loading="lazy"
            alt=""
            src="/logo.svg"
          />
          </a>
          <div className={styles.headerMenuMain}>
            <div className={styles.links}>
              <Anchorlink href="#our-work" className={styles.text}>Our Work</Anchorlink>
              <Anchorlink href="#who-we-are" className={styles.text}>Who We are</Anchorlink>
              <Anchorlink href="#careers" className={styles.text}>Careers</Anchorlink>
              <Anchorlink href="#contact-us" className={styles.text}>Contact Us</Anchorlink>
            </div>
            
          </div>
          <Anchorlink href="#contact-us" className={styles.text}>
          <div className={buttonstyles.button}>
           <span className={styles.text}>Contact Us</span> 
          </div>
          </Anchorlink>
          <div className={styles.mobilenav}>
            { <Hamburger />}
            </div>
        </div>
      </div>
    </>
  );
};

export default Header;
