import styles from "./FeatureList.module.css";

const FeatureList = ({content}) => {
  return (
    <div className={styles.featureList}>
      <img
        className={styles.image22Icon}
        loading="lazy"
        alt=""
        src={content.image}
      />
      <h3 className={styles.featureTitles}>{content.title}</h3>
      <div className={styles.featureDetails}>
      {content.description}
      </div>
    </div>
  );
};

export default FeatureList;
