import { useEffect } from "react";
//import Button from "./Button";
import styles from "./HeroSection.module.css";
import Anchorlink from "react-anchor-link-smooth-scroll";
import buttonstyles from "./Button.module.css";


const HeroSection = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroBody} data-animate-on-scroll>
          <div className={styles.text}>Transform</div>
          <h1
            className={styles.text1}
          >{`Multiply your digital journey`}</h1>
          <div
            className={styles.heroDescription}
          >{`We're multipliers, creating software that wows businesses! Our recipe? A bit of strategy, a dash of innovation, and a sprinkle of UX magic. We turn crazy ideas into solid products that keep you ahead, and we make the journey fun! `}</div>
          <Anchorlink href="#contact-us" className={styles.text}>
            <div className={buttonstyles.button}>
            <span className={buttonstyles.text}>Contact Us</span> 
            </div>
          </Anchorlink>
        </div>
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt=""
          src="/hero-image.png"
        />
      </div>
    </section>
  );
};

export default HeroSection;
