import { useState } from 'react';
import TextField from "./TextField";
import ButtonStyles from "./Button.module.css";
import styles from "./ContactUsDetail.module.css";

const ContactUsDetail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [inputValues, setInputValues] = useState({
    fullname: '',
    email: '',
    message: ''
    // Add more input values as needed
  });
  const [formErrors, setFormErrors] = useState({});
  const handleInputChange = (inputName, value) => {
    setInputValues({ ...inputValues, [inputName]: value });
    const errors = validateForm({ ...inputValues, [inputName]: value });
    setFormErrors(errors);
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm(inputValues);
    if (Object.keys(errors).length === 0) {
      // Form is valid, send email
      // alert("valid");
      fetch('send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputValues),
      })
        .then(response => response.json()) // Parse the JSON response
        .then(data => {
          if (data.success) {
            console.log(data.message); // Log success message
            // Reset form fields
            setInputValues({
              fullname: '',
              email: '',
              message: ''
            });
            alert("Message received successfully. We will get back to you");
            setFormErrors({});
          } else {
            console.error(data.message); // Log error message
            // Handle error case here
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle error case here
        });
    } else {
      // Form has errors, update state to display errors
      setFormErrors(errors);
    }
  };
  

const validateForm = (values) => {
  let errors = {};
  // Validation for full name
  if (!values.fullname.trim()) {
    errors.fullname = 'Full name is required';
  }
  // Validation for email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!values.email.trim()) {
    errors.email = 'Email is required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email format';
  }
  // Validation for message
  if (!values.message.trim()) {
    errors.message = 'Message is required';
  }

  // Remove errors if valid input is provided
  if (values.fullname.trim()) {
    delete errors.fullname;
  }
  if (values.email.trim() && emailRegex.test(values.email)) {
    delete errors.email;
  }
  if (values.message.trim()) {
    delete errors.message;
  }

  return errors;
};
 
  return (
    <section className={styles.contactUsDetail}>
      <div className={styles.contactUsDetailBody}>
        <form className={styles.contactBody} onSubmit={handleSubmit}>
          <h1 className={styles.formDescription}>Contact us</h1>
          <TextField name="fullname" id="fullname" fieldtype={"text"} label={"Name"} value={inputValues.fullname}
        onChange={(value) => handleInputChange('fullname', value)}/>
        {formErrors.fullname && <div style={{ color: 'red' }}>{formErrors.fullname}</div>}
          <TextField name="email" id="email" fieldtype={"email"} label={"Email"} value={inputValues.email}
        onChange={(value) => handleInputChange('email', value)}/>
        {formErrors.email && <div style={{ color: 'red' }}>{formErrors.email}</div>}
          <TextField name="message" id="message" label={"Message"} inputType={"textarea"} value={inputValues.message}
        onChange={(value) => handleInputChange('message', value)}/>
        {formErrors.message && <div style={{ color: 'red' }}>{formErrors.message}</div>}
        <button className={ButtonStyles.button} type="submit">Submit</button>
        </form>
        <img className={styles.imageIcon} alt="" src="/nepal.png" />
      </div>
    </section>
  );
};

export default ContactUsDetail;
