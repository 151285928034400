import ContactUsBody from "./ContactUsBody";
import styles from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <section id="contact-us" className={styles.contactUs} data-scroll-to="contactUsContainer">
      <ContactUsBody />
      <img className={styles.imageIcon} alt="" src="/arc-top.png" />
      <img className={styles.imageIcon1} alt="" src="/arc-bottom.png" />
    </section>
  );
};

export default ContactUs;
