import styles from "./Footer.module.css";
import Anchorlink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerBody}>
        <div className={styles.footerBodyContent}>
          <div className={styles.widget}>
            <a href="/">
            <img
              className={styles.logoWhiteIcon}
              alt=""
              src="/logo-white.svg"
            />
            </a>
            <div className={styles.companyDescription}>
              Let’s multiply something big together
            </div>
          </div>
          <div className={styles.widget1}>
            <b className={styles.linksHeader}>Company</b>
            <Anchorlink href="#our-work" className={styles.linkCollection}>Our Work</Anchorlink>
            <Anchorlink href="#who-we-are" className={styles.linkCollection1}>Who We are</Anchorlink>
            <Anchorlink href="#careers" className={styles.linkCollection2}>Careers</Anchorlink>
            <Anchorlink href="#contact-us" className={styles.linkCollection3}>Contact us</Anchorlink>
          </div>
          <div className={styles.widget2}>
            <b className={styles.socialHeader}>Follow us</b>
            <div className={styles.footerIcons}>
            <a href="https://www.linkedin.com/company/multiplai-technologies" target="_blank" rel="noopenar">
              <img className={styles.icon} alt="" src="/linkedin.png" />
             </a> 
              {/* <img className={styles.icon1} alt="" src="/twitter.png" /> */}
              <a href="https://www.facebook.com/multiplaitechnologies" target="_blank" rel="noopenar">
                <img className={styles.icon2} alt="" src="/facebook.png" />
              </a>
            </div>
            <div className={styles.socialLinks}>Mid-Baneshwor, Kathmandu</div>
            <div className={styles.socialLinks1}>reach@multiplai.io</div>
          </div>
        </div>
        <div className={styles.copyright}>
          <div className={styles.text}>© 2024 multiplai.io</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
