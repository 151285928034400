import styles from "./BodySection1.module.css";

const BodySection1 = ({ text, text1, text2, image21,hideLearnMore }) => {
  return (
    <div className={styles.bodySection}>
      <img className={styles.image3Icon} loading="lazy" alt="" src={image21} />
      <div className={styles.bodyContent}>
        <div className={styles.text}>{text}</div>
        <h1 className={styles.additionalDescriptions}>
          {text1}
        </h1>
        <div className={styles.additionalDetails}>
          <p className={styles.ourLoanInspection}>
            {text2}
          </p>
        </div>
        {/*!hideLearnMore ? 
          <div className={styles.button}>
            <div className={styles.moreButtonLabels}>Learn More</div> 
             <img className={styles.imageIcon} alt="" src={'/arrow-right.png'} /> 
          </div>
          : null
          */}
      </div>
    </div>
  );
};

export default BodySection1;
