import Button from "./Button";
import styles from "./HeroSection.module.css";

const HeroLoanInspection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroBody}>
          <div className={styles.text}>Streamline</div>
          <h1
            className={styles.text1}
          >{`Loan Inspection App `}</h1>
          <div
            className={styles.heroDescription}
          >{`Say goodbye to outdated paper and pen methods – digitize your inspections with our mobile app. Complete inspections on-site, upload photos instantly, and approve with digital signatures, eliminating the need for office rework.`}</div>
          <div
            className={styles.heroDescription}
          >{`With location-based locking, it promotes on-site inspections, enhancing accuracy and accountability. Experience streamlined efficiency and transparency in your inspection process with our app.
          `}</div>
          
          <Button
            text="Play Video"
            propMinWidth="6.813rem"
            propDisplay="inline-block"
          />
        </div>
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt=""
          src="/green-recharge.png"
        />
      </div>
    </section>
  );
};

export default HeroLoanInspection;
