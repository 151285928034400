import styles from "./Button.module.css";

const Button = ({ text }) => {
  
  return (
    <div className={styles.button} >
      <div className={styles.text} >
        {text}
      </div>
    </div>
  );
};

export default Button;
