import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./MultiplaiLandingPage.module.css";
import BodyLoanInspection from "../components/BodyLoanInspection";
import Features from "../components/Features";
import HeroLoanInspection from "../components/HeroLoanInspection";


const LoanInspectionSystem = () => {
  return (
    <div className={styles.multiplaiLandingPage}>
      <Header />
      <HeroLoanInspection />
      <BodyLoanInspection />
      <Features />
      <Footer />
    </div>
  );
};

export default LoanInspectionSystem;
