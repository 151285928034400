import styles from "./ContactUsBody.module.css";

const ContactUsBody = () => {
  return (
    <div className={styles.contactUsBody}>
      <h1 className={styles.text}>Drop Us a Line</h1>
      <div className={styles.contactUsBody1}>
        <img className={styles.image22Icon} alt="" src="/featured-icon.svg" />
        <div className={styles.detailItems}>Mid Baneshwor, KTM</div>
        <img className={styles.image23Icon} alt="" src="/featured-icon.svg" />
        <div className={styles.email}>reach@multiplai.io</div>
        <img className={styles.image24Icon} alt="" src="/featured-icon.svg" />
        <div className={styles.number}>9843626180</div>
      </div>
    </div>
  );
};

export default ContactUsBody;
