import BodySection1 from "./BodySection1";
import BodySection from "./BodySection";
import styles from "./Body.module.css";

const BodyLoanInspection = () => {
  return (
    <section id="our-work" className={styles.body}>
      <h1 className={styles.ourWork}>Loan Inspection System</h1>
      <BodySection1
      text="Beautifully crafted Dashboard"
      text1="Dashboard"
      text2="Effortlessly monitor inspection progress, total counts, recent activities, and yearly summaries with our sleek dashboard. Generate professional reports and conduct periodic on-field inspections seamlessly using our inspection software."
      image21="/inspection.png"
      hideLearnMore={true}
       />
      <BodySection
        text="Live Inspections"
        text1="Inspection APP"
        text2="Our inspection app simplifies location capture, photo addition, and status updates, ensuring seamless integration for efficient processes. With location-based locking, it promotes on-site inspections, enhancing accuracy and accountability. Experience streamlined efficiency and transparency in your inspection process with our app."
        image21="/green-recharge.png"
        hideLearnMore={true}
      />
      <BodySection1 
      text="conversational finbot"
      text1="AI Chatbot"
      text2="Experience seamless assistance across departments with our conversational AI chatbot, simplifying customer queries through text or voice interaction."
      image21="/chatbot.png"
      hideLearnMore={true}
      />
      <BodySection
        text="transform your technical hiring"
        text1="Interwiz"
        text2="Streamline and optimize your technical hiring. A platform to screen, interview and shortlist the best candidates all in one place through InterWiz."
        image21="/livecodepairing.png"
        hideLearnMore={true}
      />
    </section>
  );
};

export default BodyLoanInspection;
