import styles from "./BodySection.module.css";

const BodySection = ({ text, text1, text2, image21 }) => {
  return (
    <div className={styles.bodySection}>
      <div className={styles.bodyContent}>
        <div className={styles.text}>{text}</div>
        <h1 className={styles.blockDescriptions}>{text1}</h1>
        <div className={styles.blockDetails}>{text2}</div>
        <div className={styles.button}>
          {/* <div className={styles.buttonLabels}>Learn More</div>
          <img className={styles.imageIcon} alt="" src="/arrow-right.png" /> */}
        </div>
      </div>
      <img className={styles.image21Icon} loading="lazy" alt="" src={image21} />
    </div>
  );
};

export default BodySection;
