import styles from "./TextField.module.css";
import React, { useState,useEffect } from 'react';

const TextField = ({ fieldtype, label, inputType, name, id, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={styles.textField}>
      <b className={styles.label}>{label}</b>
      <div className={styles.textfield}>
        {inputType === "textarea" ? (
          <textarea
            key={name}
            name={name}
            id={id}
            rows="20"
            value={inputValue}
            onChange={handleChange}
            className={styles.placeholder}
          />
        ) : (
          <input
            key={name}
            name={name}
            id={id}
            value={inputValue}
            onChange={handleChange}
            className={styles.placeholder}
            placeholder=""
            type={fieldtype || "text"} // Use fieldtype if provided, otherwise default to "text"
          />
        )}
      </div>
    </div>
  );
};

TextField.defaultProps = {
  onChange: () => {} // Default onChange handler to avoid checking if it exists
};

export default TextField;

